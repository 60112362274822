import React from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Preferences } from '@capacitor/preferences'

// redux
import { useAppSelector, useAppDispatch } from '../app/hooks'

// user slice
import {
  clear_user,
} from '../slice/userSlice'

// components
import { Functions } from './Aside/Functions'
import { Languages } from './Aside/Languages'
import { Item } from '../storybook/Aside/Item/Item'
import { Slideshow } from '../storybook/Image/Slideshow/Slideshow'

const Aside = () => {
  const { t, i18n } = useTranslation()

  // redux
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user.user)

  const UserProfile = () => {
    if (user) {
      return (
        <div className='flex flex-col justify-center items-center pb-2 pt-3 mb-2.5 bg-gray-200 dark:bg-green-900'>
          <Slideshow
            classes="block rounded-full border border-solid border-silver cursor-pointer w-14 h-14"
            images={ [{
              'src': `${process.env.REACT_APP_BACKEND_HOST}${user.icon}`,
              'alt': 'Icon',
            }] }
          />

          <div className='w-11/12 text-left overflow-auto mt-1'>@ { user.user_id }</div>
        </div>
      )
    }
  }

  const Login = () => {
    if (!user) {
      return (
        <Item
          name={ t('Login') }
          path={ `/${i18n.language}/login` }
          theme='lime'
        />
      )
    } else {
      const clicked = async () => {
        const { value } = await Preferences.get({ key: 'user' })

        if (value) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/logout`,
            data: {
              'token': JSON.parse(value)['refresh'],
            },
            headers: {},
          })
        }

        dispatch(clear_user())
      }

      return (
        <Item
          name={ t('Logout') }
          theme='lime'
          action='customize'
          onclick_action={ clicked }
        />
      )
    }
  }

  return (
    <aside className='bg-sky-50 dark:bg-aside-bg-dark hidden lg:block text-center pb-24 w-0 lg:w-12/100 rounded'>
      <div className="flex flex-col justify-start items-stretch">
        <UserProfile />

        <Functions />

        { user &&
          <Item
            name={ t('Setting') }
            theme='lime'
            path={`/${i18n.language}/setting`}
          />
        }

        <Login />

        <Item
          name={ t('Change theme') }
          theme='lime'
          action='customize'
          onclick_action={ async () => {
            const { value } = await Preferences.get({ key: 'theme' })

            await Preferences.set({
              key: 'theme',
              value: value === 'dark' ? 'light' : 'dark',
            })

            window.location.reload()
          } }
        />

        <div className='border-0 border-b border-solid pb-1 mb-1 mt-2.5 text-center border-b-black dark:border-b-white'>Langs</div>
        <Languages />

        <div className='border-0 border-b border-solid pb-1 mb-1 mt-2.5 text-center border-b-black dark:border-b-white'>{ t('Other sites') }</div>

        {/* <Item
          name={ t('Twitter download ranking') }
          path={ `https://x.hamary.co/video_download_ranking/1` }
          theme='emerald'
          action='redirect_target_blank'
        /> */}

        <div className='border-0 border-b border-solid pb-1 mb-1 mt-2.5 text-center border-b-black dark:border-b-white'>{ t('Games') }</div>
        <Item
          name='Death Run'
          path='https://backend.hamary.co/games/DeathRun'
          theme='violet'
          action='redirect_target_blank'
        />

      </div>
    </aside>
  )
}

export default Aside